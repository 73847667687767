import { isEmpty } from 'lodash';

import { coachArrays } from 'pages/Constants';
import { setSlideTimers, setTimers } from './reducer';
import { getClassTimer } from 'helpers/classes';

export const setSlideTimerData = (lectureData, classId) => async (dispatch) => {
  try {
    const { data, status } = await getClassTimer(classId);

    if (status === 200 && !isEmpty(data)) {
      const matchedLecture = data?.filter((lecture) => {
        return lecture?.lectureConfigurationId === lectureData?.configId;
      });

      if (!isEmpty(matchedLecture)) {
        matchedLecture?.forEach((lecture) => {
          Object.keys(lecture?.slideTimers).forEach((phase) => {
            dispatch(
              setSlideTimers({
                classState: phase,
                slideTimer: lecture?.slideTimers?.[phase],
              }),
            );
          });
        });
        return;
      }
    }

    const processClassContent = (lectureData) => {
      const result = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };

      function processArray(array, targetObject) {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        if (array.some((item) => item?.type !== 'evaluation')) {
          let slideCounter = 1;
          array
            .filter((item) => item?.type !== 'evaluation')
            .forEach((item) => {
              if (item.thumbnails && Array.isArray(item.thumbnails)) {
                item.thumbnails.forEach((thumbnail) => {
                  const processedItem = {
                    elementId: item?.elementDetails?.elementId,
                    elementName: item?.elementDetails?.name,
                    elementSlideNo: thumbnail.slideNo,
                    slideNo: slideCounter,
                    timer: 0,
                  };
                  targetObject[slideCounter++] = processedItem;
                });
              } else {
                console.warn(
                  'Expected an array of thumbnails, but got:',
                  item.thumbnails,
                );
              }
            });
        } else {
          console.log('All items are evaluations');
          return;
        }
      }

      if (Array.isArray(lectureData?.teach)) {
        processArray(lectureData?.teach, result?.teach);
      }

      coachArrays.forEach((coachArray) => {
        if (Array.isArray(lectureData?.[coachArray])) {
          processArray(lectureData?.[coachArray], result?.[coachArray]);
        }
      });

      return result;
    };

    const result = processClassContent(lectureData);

    Object.keys(result).forEach((phase) => {
      dispatch(
        setSlideTimers({
          classState: phase,
          slideTimer: result[phase],
        }),
      );
    });
  } catch (err) {
    console.log('error in setting slide timers', err);
  }
};

export const setTimerData = (lectureData, classId) => async (dispatch) => {
  try {
    const { data, status } = await getClassTimer(classId);

    if (status === 200 && !isEmpty(data)) {
      const matchedLecture = data?.filter((lecture) => {
        return lecture?.lectureConfigurationId === lectureData?.configId;
      });

      if (!isEmpty(matchedLecture)) {
        matchedLecture?.forEach((lecture) => {
          Object.keys(lecture?.elementTimers).forEach((phase) => {
            dispatch(
              setTimers({
                classState: phase,
                timer: lecture?.elementTimers?.[phase],
              }),
            );
          });
        });
        return;
      }
    }

    const processClassContent = (lectureData) => {
      const result = {
        teach: {},
        coach_basic: {},
        coach_intermediate: {},
        coach_advanced: {},
      };

      function processArray(array, targetObject) {
        if (!Array.isArray(array)) {
          console.warn('Expected an array, but got:', array);
          return;
        }

        let elementCounter = 0;
        array.forEach((item) => {
          const processedItem = {
            slideType: item?.type,
            title: item?.elementDetails?.name || item?.title || null,
            timer: 0,
            elementId: item?.elementDetails?.elementId || null,
            evaluationId: item?.evaluationId || null,
            evaluationType: item?.evaluationType || null,
            evaluationAt: item?.evaluateAt || null,
          };
          targetObject[elementCounter++] = processedItem;
        });
      }

      if (Array.isArray(lectureData.teach)) {
        processArray(lectureData.teach, result.teach);
      }

      coachArrays.forEach((coachArray) => {
        if (Array.isArray(lectureData?.[coachArray])) {
          processArray(lectureData?.[coachArray], result?.[coachArray]);
        }
      });

      return result;
    };

    const result = processClassContent(lectureData);

    Object.keys(result).forEach((phase) => {
      dispatch(
        setTimers({
          classState: phase,
          timer: result[phase],
        }),
      );
    });
  } catch (err) {
    console.log('error in setting element timers', err);
  }
};
